import { useEffect } from 'react';
import { navigate } from 'gatsby';
import cookies from 'js-cookie';

export default () => {
  useEffect(() => {
    const lang = cookies.get('favorite_language')
    if (lang) {
      navigate(`/${lang}`);
    } else {
      navigate('/fr-be');
    }
  }, []);
  return null;
};